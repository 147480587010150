import { AuthProvider } from './AuthContext';
import { FetcherProvider } from './FetcherContext';
import { GraphQLClientProvider } from './GraphQLClientContext';
import { I18nProvider } from './I18nContext';
import { MaterialUIProvider } from './MaterialUIContext';
import { SessionProvider } from './SessionContext';
import { UserProvider } from './UserContext';

const AppProviders = ({ children }) => (
  <I18nProvider>
    <SessionProvider>
      <FetcherProvider>
        <GraphQLClientProvider>
          <UserProvider>
            <AuthProvider>
              <MaterialUIProvider>{children}</MaterialUIProvider>
            </AuthProvider>
          </UserProvider>
        </GraphQLClientProvider>
      </FetcherProvider>
    </SessionProvider>
  </I18nProvider>
);

export default AppProviders;
