export const UserProfileQuery = /* GraphQL */ `
  query UserProfile {
    me {
      id
      avatarUrl
      firstName
      lastName
      fullName
      initials
      email
      role {
        id
        name
        permissions
      }
      status
      superuser
    }
  }
`;

export const MemberSearchQuery = /* GraphQL */ `
  query MemberSearch($query: String) {
    members(query: $query) {
      id
      avatarUrl
      fullName
      initials
      dateOfBirth
      email
      phoneNumber
    }
  }
`;

export const MembersConnectionQuery = /* GraphQL */ `
  query MembersConnection($page: PageInput!) {
    membersConnection(page: $page) {
      members {
        id
        avatarUrl
        fullName
        initials
        dateOfBirth
        email
        phoneNumber
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export const MemberDetailQuery = /* GraphQL */ `
  query MemberDetail($id: ID!) {
    member(id: $id) {
      id
      avatarUrl
      firstName
      lastName
      fullName
      initials
      email
      phoneNumber
      createdAt
      activeMembership {
        unlimitedPass
        availableCredits
      }
    }
  }
`;

export const MemberBookingListQuery = /* GraphQL */ `
  query MemberBookingList($id: ID!) {
    member(id: $id) {
      id
      bookings {
        id
        classSession {
          id
          class {
            id
            name
          }
          startAt
          canceled
        }
        createdBy {
          id
          fullName
        }
        createdAt
        canceled
        canceledBy {
          id
          fullName
        }
        canceledAt
        lateCancelation
      }
    }
  }
`;

export const MemberCreditsPurchaseHistoryQuery = /* GraphQL */ `
  query MemberCreditsPurchaseHistory($id: ID!) {
    member(id: $id) {
      id
      memberships {
        id
        unlimitedPass
        credits
        availableCredits
        createdAt
        expireAt
        expired
      }
    }
  }
`;

export const MemberWorkoutListQuery = /* GraphQL */ `
  query MemberWorkoutList($id: ID!) {
    member(id: $id) {
      id
      workouts {
        id
        focus
        date
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
                videoUrl
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const MemberWorkoutDetailQuery = /* GraphQL */ `
  query MemberWorkoutDetail($memberId: ID!, $workoutId: ID!) {
    member(id: $memberId) {
      id
      fullName
      workout(id: $workoutId) {
        id
        focus
        date
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
                videoUrl
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const ClassListQuery = /* GraphQL */ `
  query ClassList {
    classes {
      id
      name
    }
  }
`;

export const ClassesConnectionQuery = /* GraphQL */ `
  query ClassesConnection($page: PageInput!) {
    classesConnection(page: $page) {
      classes {
        id
        name
        photoUrl
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export const ClassDetailQuery = /* GraphQL */ `
  query ClassDetail($id: ID!) {
    class(id: $id) {
      id
      name
      description
      intensity
      photoUrl
    }
  }
`;

export const ClassTimetableQuery = /* GraphQL */ `
  query ClassTimetable($id: ID!, $from: DateTime!, $to: DateTime!) {
    class(id: $id) {
      id
      timetable(from: $from, to: $to) {
        id
        startAt
        venue {
          id
          name
        }
        instructor {
          id
          firstName
          nickname
        }
      }
    }
  }
`;

export const ClassWorkoutListQuery = /* GraphQL */ `
  query ClassWorkoutList($id: ID!) {
    class(id: $id) {
      id
      workouts {
        id
        focus
        date
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
                videoUrl
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const ClassWorkoutDetailQuery = /* GraphQL */ `
  query ClassWorkoutDetail($classId: ID!, $workoutId: ID!) {
    class(id: $classId) {
      id
      name
      workout(id: $workoutId) {
        id
        focus
        date
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
                videoUrl
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const ClassSessionDetailQuery = /* GraphQL */ `
  query ClassSessionDetail($id: ID!) {
    classSession(id: $id) {
      id
      class {
        id
        name
      }
      startAt
      endAt
      duration
      capacity
      occupiedSpots
      canceled
      venue {
        id
        name
      }
      instructor {
        id
        fullName
      }
      workout {
        id
        focus
      }
    }
  }
`;

export const ClassSessionBookingListQuery = /* GraphQL */ `
  query ClassSessionBookingList($id: ID!) {
    classSession(id: $id) {
      id
      bookings {
        id
        classSession {
          id
          startAt
        }
        member {
          id
          avatarUrl
          fullName
          initials
        }
        createdBy {
          id
          fullName
        }
        createdAt
        canceled
        canceledBy {
          id
          fullName
        }
        canceledAt
        lateCancelation
      }
    }
  }
`;

export const AddClassSessionsFormQuery = /* GraphQL */ `
  query AddClassSessionsForm {
    locations {
      id
      name
    }
    instructors {
      id
      fullName
    }
  }
`;

export const TimetableQuery = /* GraphQL */ `
  query Timetable($from: DateTime!, $to: DateTime!) {
    timetable(from: $from, to: $to) {
      id
      class {
        id
        name
      }
      startAt
      venue {
        id
        name
      }
      instructor {
        id
        firstName
        nickname
      }
    }
  }
`;

export const WorkoutTemplateListQuery = /* GraphQL */ `
  query WorkoutTemplateList {
    workoutTemplates {
      id
      name
      focus
      intensity
      level
    }
  }
`;

export const WorkoutTemplateDetailQuery = /* GraphQL */ `
  query WorkoutTemplateDetail($id: ID!) {
    workoutTemplate(id: $id) {
      id
      name
      focus
      intensity
      level
      notes
      blocks {
        type
        time
        rounds
        name
        notes
        exerciseGroups {
          exercises {
            exercise {
              id
              name
            }
            sets
            reps {
              value
              unit
            }
            load {
              value
              unit
            }
            tempo
            rest
          }
        }
      }
    }
  }
`;

export const ExerciseListQuery = /* GraphQL */ `
  query ExerciseList {
    exercises {
      id
      name
      muscleGroup
      equipment
    }
  }
`;

export const ExercisesConnectionQuery = /* GraphQL */ `
  query ExercisesConnection($page: PageInput!) {
    exercisesConnection(page: $page) {
      exercises {
        id
        name
        muscleGroup
        equipment
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export const ExerciseDetailQuery = /* GraphQL */ `
  query ExerciseDetail($id: ID!) {
    exercise(id: $id) {
      id
      name
      description
      videoUrl
      muscleGroup
      equipment
    }
  }
`;

export const UserListQuery = /* GraphQL */ `
  query UserList {
    users {
      id
      avatarUrl
      fullName
      initials
      email
      role {
        id
        name
        permissions
      }
      status
      superuser
    }
  }
`;

export const UsersConnectionQuery = /* GraphQL */ `
  query UsersConnection($page: PageInput!) {
    usersConnection(page: $page) {
      users {
        id
        avatarUrl
        fullName
        initials
        email
        role {
          id
          name
        }
        status
        superuser
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export const UserDetailQuery = /* GraphQL */ `
  query UserDetail($id: ID!) {
    user(id: $id) {
      id
      avatarUrl
      firstName
      lastName
      fullName
      initials
      email
      role {
        id
        name
      }
      status
      superuser
    }
  }
`;

export const UserFormQuery = /* GraphQL */ `
  query UserForm {
    roles {
      id
      name
    }
  }
`;

export const InstructorListQuery = /* GraphQL */ `
  query InstructorList {
    instructors {
      id
      avatarUrl
      initials
      fullName
    }
  }
`;

export const InstructorsConnectionQuery = /* GraphQL */ `
  query InstructorsConnection($page: PageInput!) {
    instructorsConnection(page: $page) {
      instructors {
        id
        avatarUrl
        initials
        fullName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export const InstructorDetailQuery = /* GraphQL */ `
  query InstructorDetail($id: ID!) {
    instructor(id: $id) {
      id
      avatarUrl
      firstName
      lastName
      fullName
      initials
      nickname
      bio
    }
  }
`;
