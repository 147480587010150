import '@reach/dialog/styles.css';
import '@reach/tabs/styles.css';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import { Global, css } from '@emotion/react';

import { DrukWideMediumItalic } from '../../fonts';
import { barlowSemiCondensed, drukWide } from '../../theme/fonts';
import { black, white } from '../../theme/colors';
import { bottomPadding, scrollbarWidth, unit } from '../../theme/sizes';

const fonts = css`
  @font-face {
    font-family: ${drukWide};
    font-style: italic;
    font-weight: 500;
    src: local('Druk-WideMediumItalic'),
      url('${DrukWideMediumItalic}') format('truetype');
  }
`;

const scrollbarThumbWidth = unit * 0.5;
const scrollbarLeftMargin = scrollbarWidth - scrollbarThumbWidth;
const scrollbar = css`
  ::-webkit-scrollbar {
    width: ${scrollbarWidth}px;
  }

  ::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:vertical {
    box-shadow: inset ${scrollbarThumbWidth}px 0 rgba(255, 255, 255, 0.15);
    border-bottom: ${bottomPadding}px solid transparent;
    border-left: ${scrollbarLeftMargin}px solid transparent;
  }
`;

const styles = css`
  *,
  ::before,
  ::after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    background-color: ${black};
    color: ${white};
    font-family: ${barlowSemiCondensed};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /*
   * Absolute position ensures no extra space.
   * See: https://github.com/csstools/sanitize.css/issues/203#issuecomment-693983465
   */
  nav li:before {
    position: absolute;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  th {
    text-align: inherit;
  }

  fieldset {
    border: none;
    margin: 0;
    min-width: 0;
    padding: 0;
  }

  legend {
    display: block;
    line-height: inherit;
    padding: 0;
    width: 100%;
  }

  select:not([multiple]):not([size]) {
    background-image: none;
  }
`;

const GlobalStyles = () => <Global styles={[fonts, scrollbar, styles]} />;

export default GlobalStyles;
