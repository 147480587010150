import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const Whistle = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill={color} fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M64.3 44.57v.001l.014.192c1.59 9.02-4.08 17.59-12.644 19.1-8.563 1.51-16.824-4.604-18.414-13.624-1.59-9.018 4.081-17.59 12.644-19.1l41.882-7.384-1.598 9.551-.285.134-20.704 9.68-.036.022-.244.154-.052.033c-.017.013-.03.024-.06.051l-.048.041-.004.004c-.019.018-.044.046-.138.15-.08.115-.095.137-.105.155l-.033.061-.013.025-.021.044-.03.062c-.012.03-.023.065-.059.168a3.48 3.48 0 0 0-.038.182l-.001.012-.004.042c-.009.094-.01.114-.01.175.001-.005.001 0 .001.068zM91.02 21.63a7.168 7.168 0 0 0-.06-.21h.002c-.01-.03-.023-.06-.046-.117l-.077-.16a4.682 4.682 0 0 0-.17-.22l-.001-.001-.057-.062c-.008-.009-.046-.041-.163-.149a2.407 2.407 0 0 0-.288-.16c-.021-.01-.043-.018-.213-.08-.265-.04-.302-.044-.349-.043-.025 0-.082.004-.274.017l-43.942 7.748C35.22 29.986 28.482 40.102 30.36 50.75 32.238 61.4 42.03 68.6 52.19 66.81c9.6-1.693 16.226-10.868 15.23-20.987l-.041-.422.384-.18 20.272-9.48.239-.133a1.873 1.873 0 0 0 .148-.1c.054-.04.099-.08.135-.12l.119-.144.015-.024c.039-.056.064-.093.074-.11l.01-.017c.016-.03.033-.063.075-.149.038-.1.046-.124.059-.16l.045-.17 2.073-12.384a4.84 4.84 0 0 0 .022-.346c0-.02-.003-.052-.019-.21l-.011-.043zM48.73 55.983a8.4 8.4 0 1 0 0-16.8 8.4 8.4 0 0 0 0 16.8zm0-2.7a5.7 5.7 0 1 1 0-11.4 5.7 5.7 0 0 1 0 11.4z"
      />
      <path
        d="M21 17.586L24.586 14 26 15.414 22.414 19l3.485 3.485-1.414 1.414L21 20.414 17.515 23.9 16.1 22.485 19.586 19 16 15.414 17.414 14 21 17.586zM94 64.737l2.869-2.868L98 63l-2.869 2.869 2.789 2.788-1.132 1.131L94 67l-2.788 2.788-1.132-1.131 2.789-2.788L90 63l1.131-1.131L94 64.737z"
        opacity=".404"
      />
    </g>
  </svg>
);

export default Whistle;
