import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const MemberCard = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill="none" fillRule="evenodd">
      <path stroke={color} strokeWidth="3" d="M31 13H91V49H31z" />
      <path
        stroke={color}
        strokeWidth="2.5"
        d="M47 33.5L42.886 35.663 43.671 31.082 40.343 27.837 44.943 27.168 47 23 49.057 27.168 53.657 27.837 50.329 31.082 51.114 35.663z"
      />
      <path
        stroke={color}
        strokeWidth="3"
        d="M75 69L61 59.196 47 69 47 59.683 47 49 61 49 75 49z"
      />
      <path
        fill={color}
        d="M18.586 57L20 58.414 16.414 62l3.485 3.485-1.414 1.414L15 63.414l-3.485 3.485-1.414-1.414L13.586 62 10 58.414 11.414 57 15 60.586 18.586 57zM110.869 10.869L112 12l-2.869 2.868 2.789 2.789-1.132 1.131L108 16l-2.788 2.79-1.132-1.132 2.788-2.789L104 12l1.131-1.131L108 13.737l2.869-2.868z"
        opacity=".404"
      />
      <path fill={color} d="M60 27H85V30H60zM60 33H76V36H60z" />
    </g>
  </svg>
);

export default MemberCard;
