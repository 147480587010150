import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const BrokenDumbbell = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill={color} fillRule="evenodd">
      <path
        fillRule="nonzero"
        stroke={color}
        d="M20.173 41.53V34h14.223v11.294h17.651l-3 4.706 3 4.706H34.396V66H20.173v-7.53H11V41.53h9.173zm0 1.6h-7.561v13.74h7.561V43.13zm1.612 15.34v5.93h10.999V35.6H21.785v22.87zm12.61-11.576v6.212h15.367l-2-3.106 2-3.106H34.396zM92.292 62.842l-2.575 7.075-13.364-4.864 3.863-10.613-16.588-6.037 4.429-3.396-1.21-5.449 16.588 6.038 3.862-10.613 13.365 4.864-2.575 7.075 8.62 3.138-5.794 15.92-8.62-3.138zm.548-1.504l7.105 2.586 4.7-12.912-7.106-2.586-4.7 12.912zm3.732-14.967l2.028-5.572-10.335-3.762-9.85 27.064 10.335 3.761 2.028-5.571 5.794-15.92zm-15.81 6.565l2.125-5.837-14.44-5.256.818 3.603-2.942 2.235 14.44 5.255z"
      />
      <path d="M58 7h2.8v12H58V7zm-15.982 3.06l2.538-1.183 4.226 9.063-2.538 1.183-4.226-9.063zm34.764 0l-4.226 9.063-2.538-1.183 4.226-9.063 2.538 1.183z" />
    </g>
  </svg>
);

export default BrokenDumbbell;
