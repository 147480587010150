import { dedupExchange, fetchExchange } from 'urql';
import { devtoolsExchange } from '@urql/devtools'; // eslint-disable-line

import cacheExchange from './cache';

const noopExchange = ({ forward }) => forward;

const exchanges = [
  process.env.NODE_ENV !== 'production' ? devtoolsExchange : noopExchange,
  dedupExchange,
  cacheExchange,
  fetchExchange,
];

export default exchanges;
