import { useContext } from 'react';

import FetcherContext from '../context/FetcherContext';

const useFetcher = () => {
  const context = useContext(FetcherContext);
  if (typeof context === 'undefined') {
    throw new Error('useFetcher must be used within a FetcherProvider');
  }

  return context;
};

export default useFetcher;
