import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const ClipboardCheck = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M15 23.586L18.586 20 20 21.414 16.414 25l3.485 3.485-1.414 1.414L15 26.414 11.515 29.9 10.1 28.485 13.586 25 10 21.414 11.414 20 15 23.586zM104 57.737l2.869-2.868L108 56l-2.869 2.869 2.789 2.788-1.132 1.131L104 60l-2.788 2.788-1.132-1.131 2.789-2.788L100 56l1.131-1.131L104 57.737z"
        opacity=".404"
      />
      <g fill={color} fillRule="nonzero">
        <path d="M82.5 19.5v45h-45v-45h10.772v-3H34.5v51h51v-51H71.588v3z" />
        <path d="M73.5 12.5v12h-27v-12h7.344c1.235-1.988 3.353-3 6.156-3 2.803 0 4.921 1.012 6.156 3H73.5zm-3 3h-6.235l-.392-.904C63.261 13.18 62.07 12.5 60 12.5c-2.07 0-3.261.681-3.873 2.096l-.392.904H49.5v6h21v-6z" />
      </g>
      <path stroke={color} strokeWidth="3" d="M51 40.328L56.93 47 70 35" />
    </g>
  </svg>
);

export default ClipboardCheck;
