import { createContext, useCallback } from 'react';

import useSession from '../hooks/useSession';

const FetcherContext = createContext();

export const FetcherProvider = ({ children }) => {
  const [session, setSession] = useSession();

  const fetcher = useCallback(
    async (input, init = {}) => {
      const response = await fetch(input, {
        ...init,
        headers: {
          ...(session && { Authorization: `Bearer ${session}` }),
          ...init.headers,
        },
      });

      if (response.status === 401) {
        setSession(null);
      }

      return response;
    },
    [session, setSession],
  );

  return (
    <FetcherContext.Provider value={fetcher}>
      {children}
    </FetcherContext.Provider>
  );
};

export default FetcherContext;
