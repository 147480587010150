import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const Medal = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill="none" fillRule="evenodd">
      <g stroke={color} strokeWidth="3">
        <path d="M58.5 59.37l-11.462 6.026 2.19-12.763-9.274-9.039 12.815-1.862L58.5 30.12l5.73 11.612 12.816 1.862-9.273 9.039 2.189 12.763z" />
        <path d="M45.5 33.5V11.295h25.378V33.5" />
      </g>
      <path
        fill={color}
        d="M21 56.586L24.586 53 26 54.414 22.414 58l3.485 3.485-1.414 1.414L21 59.414 17.515 62.9 16.1 61.485 19.586 58 16 54.414 17.414 53 21 56.586zM94 19.737l2.869-2.868L98 18l-2.869 2.869 2.789 2.788-1.132 1.131L94 22l-2.788 2.788-1.132-1.131 2.789-2.788L90 18l1.131-1.131L94 19.737z"
        opacity=".404"
      />
    </g>
  </svg>
);

export default Medal;
