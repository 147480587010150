import { createContext, useCallback, useMemo, useState } from 'react';

const SessionContext = createContext();

const sessionKey = 'session';

export const SessionProvider = ({ children }) => {
  const [storedSession, setStoredSession] = useState(() => {
    try {
      const session = window.localStorage.getItem(sessionKey);
      return session ? JSON.parse(session) : null;
    } catch (err) {
      return null;
    }
  });

  const setSession = useCallback((session) => {
    try {
      if (session) {
        window.localStorage.setItem(sessionKey, JSON.stringify(session));
      } else {
        window.localStorage.removeItem(sessionKey);
      }
    } catch (err) {
      // Ignore error (session will not be remembered).
    }

    setStoredSession(session);
  }, []);

  const value = useMemo(
    () => [storedSession, setSession],
    [storedSession, setSession],
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export default SessionContext;
