import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const Calendar = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill="none" fillRule="evenodd">
      <path stroke={color} strokeWidth="3" d="M36 18h48v45H36z" />
      <path
        fill={color}
        d="M36 27h48v3H36zM45 11h4v6h-4zM71 11h4v6h-4zM45 36h6v6h-6v-6zm12 0h6v6h-6v-6zm12 0h6v6h-6v-6zM45 48h6v6h-6v-6zm12 0h6v6h-6v-6zm12 0h6v6h-6v-6z"
      />
      <path
        fill={color}
        d="M15 23.586L18.586 20 20 21.414 16.414 25l3.485 3.485-1.414 1.414L15 26.414 11.515 29.9 10.1 28.485 13.586 25 10 21.414 11.414 20 15 23.586zM104 57.737l2.869-2.868L108 56l-2.869 2.869 2.789 2.788-1.132 1.131L104 60l-2.788 2.788-1.132-1.131 2.789-2.788L100 56l1.131-1.131L104 57.737z"
        opacity=".404"
      />
    </g>
  </svg>
);

export default Calendar;
