export const white = '#ffffff';

export const whiteSmoke = '#f2f2f2';

export const black = '#010101';

export const darkestGray = '#141414';

export const darkGray = '#1f1f1f';

export const gray = '#505050';

export const lightGray = '#a5a5a5';

export const green = '#00e789';

export const red = '#ff5959';

export const accent = process.env.REACT_APP_ACCENT_COLOR || '#cbff00';
