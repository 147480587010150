import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from 'urql';

import Loading from '../components/UI/Loading';
import useSession from '../hooks/useSession';
import useTranslate from '../hooks/useTranslate';
import useUser from '../hooks/useUser';
import { SignInMutation } from '../graphql/mutations';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [waitingForUser, setWaitingForUser] = useState(true);

  const [user, fetchUser] = useUser();

  const [session, setSession] = useSession();

  const t = useTranslate();

  const [, createSession] = useMutation(SignInMutation);
  const signIn = useCallback(
    async (email, password) => {
      const { data, error } = await createSession({
        input: {
          email,
          password,
        },
      });

      if (error) {
        const authenticationError =
          error.graphQLErrors &&
          error.graphQLErrors.find(
            (err) => err.extensions.code === 'UNAUTHENTICATED',
          );
        if (authenticationError) {
          if (authenticationError.message.includes('disabled')) {
            throw new Error(t('web.context.AuthContext.disabledAccount'));
          }

          throw new Error(t('web.context.AuthContext.invalidCredentials'));
        }

        throw new Error(t('web.context.AuthContext.unknownError'));
      }

      const {
        signIn: { token },
      } = data;
      setSession(token);
    },
    [createSession, setSession, t],
  );

  const signOut = useCallback(() => {
    setSession(null);
  }, [setSession]);

  useEffect(() => {
    const updateUser = async () => {
      if (session) {
        try {
          await fetchUser();
        } catch (err) {
          // TODO: Only log out on 401, show error with retry otherwise.
          signOut();
        }

        setWaitingForUser(false);
      } else {
        setWaitingForUser(false);
      }
    };

    if (!user) {
      updateUser();
    }
  }, [session, user, fetchUser, signOut]);

  const value = useMemo(
    () => ({
      signIn,
      signOut,
      isAuthenticated: !!user,
    }),
    [signIn, signOut, user],
  );

  if (waitingForUser) {
    return <Loading fullHeight />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
