import { Suspense, lazy, useEffect } from 'react';

import Loading from './components/UI/Loading';
import useIsAuthenticated from './hooks/useIsAuthenticated';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));

const App = () => {
  const isAuthenticated = useIsAuthenticated();

  // Pre-load the authenticated side in the background while the user is
  // filling out the sign-in form.
  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <Suspense fallback={<Loading fullHeight />}>
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};

export default App;
