import { createClient } from 'urql';

import exchanges from './exchanges';

const url = '/admin/api/graphql';

const makeClient = (fetcher) =>
  createClient({
    url,
    exchanges,
    fetch: fetcher,
    maskTypename: true,
  });

export default makeClient;
