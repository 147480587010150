import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const Stopwatch = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill={color} fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M75.531 22.654l-.58-.41C70.582 19.16 65.41 17.483 60 17.483c-14.471 0-26.25 11.946-26.25 26.634C33.75 58.804 45.53 70.75 60 70.75c14.471 0 26.25-11.946 26.25-26.633 0-7.117-2.78-13.8-7.64-18.763l-.462-.471 3.438-4.358-2.668-2.165-3.387 4.294zM60 67.272c-12.598 0-22.841-10.39-22.841-23.155 0-12.767 10.244-23.156 22.841-23.156 12.598 0 22.842 10.39 22.842 23.156 0 12.765-10.244 23.155-22.842 23.155zM68.582 9.25H51.417v3.478h17.165V9.25zm-6.878 36.52V28.897h-3.409V45.77h3.409z"
      />
      <path
        d="M17 57.586L20.586 54 22 55.414 18.414 59l3.485 3.485-1.414 1.414L17 60.414 13.515 63.9 12.1 62.485 15.586 59 12 55.414 13.414 54 17 57.586zM103 20.737l2.869-2.868L107 19l-2.869 2.869 2.789 2.788-1.132 1.131L103 23l-2.788 2.788-1.132-1.131 2.789-2.788L99 19l1.131-1.131L103 20.737z"
        opacity=".404"
      />
    </g>
  </svg>
);

export default Stopwatch;
