import { isValidElementType } from 'react-is';

const elementTypePropTypeValidator = (props, propName, componentName) => {
  if (isValidElementType(props[propName])) {
    return null;
  }

  return new Error(
    `Invalid prop ${propName} supplied to ${componentName}. The prop is not a valid React element.`,
  );
};

export default elementTypePropTypeValidator;
