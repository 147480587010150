import * as Sentry from '@sentry/browser';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import Root from './Root';

if (process.env.NODE_ENV === 'production') {
  const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
  Sentry.init({ dsn: sentryDSN });
}

ReactDOM.render(
  <StrictMode>
    <Root />
  </StrictMode>,
  document.getElementById('root'),
);
