import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const User = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill={color} fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M33.533 67C31 67 29 64.946 29 62.428v-7.223c0-1.59.8-2.982 2.133-3.844 7.734-4.837 16.6-7.421 25.734-7.421h.333c9.133 0 18 2.584 25.667 7.421C84.2 52.157 85 53.614 85 55.205v7.223C85 64.946 82.933 67 80.467 67H33.533zm-1.866-11.795v7.223c0 1.06.866 1.921 1.866 1.921H80.4c1 0 1.867-.861 1.867-1.921v-7.223c0-.597-.334-1.26-.867-1.59a45.56 45.56 0 0 0-24.267-7.025H56.8c-8.6 0-17 2.452-24.267 7.024a1.862 1.862 0 0 0-.866 1.59zM55.733 39.83c-5.2 0-9.4-4.174-9.4-9.41v-9.078c0-5.168 4.2-9.343 9.467-9.343h2.467c5.2 0 9.4 4.175 9.4 9.343v9.145c0 5.169-4.2 9.343-9.467 9.343h-2.467zM49 21.343v9.145c0 3.645 3.067 6.693 6.8 6.693h2.467c3.666 0 6.733-3.048 6.733-6.76v-9.078c0-3.644-3.067-6.692-6.8-6.692h-2.467c-3.666 0-6.733 3.048-6.733 6.692z"
      />
      <path
        d="M25 19.422l3.586-3.586L30 17.25l-3.586 3.586L29.9 24.32l-1.414 1.414L25 22.25l-3.485 3.485-1.414-1.414 3.485-3.485L20 17.25l1.414-1.414L25 19.422zm72 24.315l2.869-2.868L101 42l-2.869 2.869 2.789 2.788-1.132 1.131L97 46l-2.788 2.788-1.132-1.131 2.789-2.788L93 42l1.131-1.131L97 43.737z"
        opacity=".404"
      />
    </g>
  </svg>
);

export default User;
