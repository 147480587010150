/** @jsxImportSource @emotion/react */
import * as Sentry from '@sentry/browser';
import { Component } from 'react';

import Button from '../UI/Button';
import EmptyState from '../UI/EmptyState';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (!hasError) {
      return children;
    }

    return (
      <EmptyState
        actions={[
          <Button onClick={() => window.location.reload()}>Recargar</Button>,
        ]}
        description="Por favor, vuelve a cargar la página"
        fullHeight
        graphic="broken-dumbbell"
        title="Ups! Ha ocurrido un error..."
      />
    );
  }
}

export default ErrorBoundary;
