export const unit = 8;

export const headerHeight = unit * 7;

export const desktopHeaderHeight = unit * 17;

export const navigationHeaderHeight = unit * 10;

export const bottomPadding = unit * 5;

export const navigationWidth = unit * 32;

export const mainWidth = unit * 112;

export const mainWithSidebarWidth = unit * 80;

export const narrowContentWidth = unit * 74;

export const scrollbarWidth = unit * 3;

export const dialogWidth = unit * 71;

export const sidebarWidth = unit * 38;
