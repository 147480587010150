import PropTypes from 'prop-types';

import BrokenDumbbell from './BrokenDumbbell';
import Calendar from './Calendar';
import ClipboardCheck from './ClipboardCheck';
import Dumbbell from './Dumbbell';
import Medal from './Medal';
import MemberCard from './MemberCard';
import Pin from './Pin';
import Stopwatch from './Stopwatch';
import User from './User';
import Whistle from './Whistle';

const Graphic = ({ name, ...other }) => {
  switch (name) {
    case 'broken-dumbbell':
      return <BrokenDumbbell {...other} />;
    case 'calendar':
      return <Calendar {...other} />;
    case 'clipboard-check':
      return <ClipboardCheck {...other} />;
    case 'dumbbell':
      return <Dumbbell {...other} />;
    case 'medal':
      return <Medal {...other} />;
    case 'member-card':
      return <MemberCard {...other} />;
    case 'pin':
      return <Pin {...other} />;
    case 'stopwatch':
      return <Stopwatch {...other} />;
    case 'user':
      return <User {...other} />;
    case 'whistle':
      return <Whistle {...other} />;
    default:
      return null;
  }
};

Graphic.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Graphic;
