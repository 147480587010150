export const SignInMutation = /* GraphQL */ `
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      token
    }
  }
`;

export const BeginPasswordResetMutation = /* GraphQL */ `
  mutation BeginPasswordReset($input: BeginPasswordResetInput!) {
    beginPasswordReset(input: $input) {
      ok
    }
  }
`;

export const ResetPasswordMutation = /* GraphQL */ `
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      ok
    }
  }
`;

export const CreateMemberMutation = /* GraphQL */ `
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      member {
        id
        avatarUrl
        firstName
        lastName
        fullName
        initials
        email
        phoneNumber
        createdAt
      }
    }
  }
`;

export const UpdateMemberMutation = /* GraphQL */ `
  mutation UpdateMember($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      member {
        id
        avatarUrl
        firstName
        lastName
        fullName
        initials
        email
        phoneNumber
      }
    }
  }
`;

export const AddMemberCreditsMutation = /* GraphQL */ `
  mutation AddMemberCredits($input: AddMemberCreditsInput!) {
    addMemberCredits(input: $input) {
      membership {
        id
        unlimitedPass
        credits
        createdAt
        expireAt
        expired
      }
    }
  }
`;

export const CreateMemberWorkoutMutation = /* GraphQL */ `
  mutation CreateMemberWorkout($input: CreatePlanWorkoutInput!) {
    createMemberWorkout(input: $input) {
      workout {
        id
        focus
        date
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
                videoUrl
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const UpdateMemberWorkoutMutation = /* GraphQL */ `
  mutation UpdateMemberWorkout($input: UpdatePlanWorkoutInput!) {
    updateMemberWorkout(input: $input) {
      workout {
        id
        focus
        date
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
                videoUrl
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const DeleteMemberWorkoutMutation = /* GraphQL */ `
  mutation DeleteMemberWorkout($input: DeletePlanWorkoutInput!) {
    deleteMemberWorkout(input: $input) {
      ok
    }
  }
`;

export const CreateClassMutation = /* GraphQL */ `
  mutation CreateClass($input: CreateClassInput!) {
    createClass(input: $input) {
      class {
        id
        name
        description
        intensity
        photoUrl
      }
    }
  }
`;

export const UpdateClassMutation = /* GraphQL */ `
  mutation UpdateClass($input: UpdateClassInput!) {
    updateClass(input: $input) {
      class {
        id
        name
        description
        intensity
        photoUrl
      }
    }
  }
`;

export const DeleteClassMutation = /* GraphQL */ `
  mutation DeleteClass($input: DeleteClassInput!) {
    deleteClass(input: $input) {
      ok
    }
  }
`;

export const CreateClassSessionsMutation = /* GraphQL */ `
  mutation CreateClassSessions($input: CreateClassSessionsInput!) {
    createClassSessions(input: $input) {
      ok
    }
  }
`;

export const CancelClassSessionMutation = /* GraphQL */ `
  mutation CancelClassSession($input: CancelClassSessionInput!) {
    cancelClassSession(input: $input) {
      ok
    }
  }
`;

export const CreateClassWorkoutMutation = /* GraphQL */ `
  mutation CreateClassWorkout($input: CreatePlanWorkoutInput!) {
    createClassWorkout(input: $input) {
      workout {
        id
        focus
        date
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
                videoUrl
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const UpdateClassWorkoutMutation = /* GraphQL */ `
  mutation UpdateClassWorkout($input: UpdatePlanWorkoutInput!) {
    updateClassWorkout(input: $input) {
      workout {
        id
        focus
        date
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
                videoUrl
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const DeleteClassWorkoutMutation = /* GraphQL */ `
  mutation DeleteClassWorkout($input: DeletePlanWorkoutInput!) {
    deleteClassWorkout(input: $input) {
      ok
    }
  }
`;

export const CreateWorkoutTemplateMutation = /* GraphQL */ `
  mutation CreateWorkoutTemplate($input: CreateWorkoutTemplateInput!) {
    createWorkoutTemplate(input: $input) {
      workoutTemplate {
        id
        name
        focus
        intensity
        level
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
      }
    }
  }
`;

export const UpdateWorkoutTemplateMutation = /* GraphQL */ `
  mutation UpdateWorkoutTemplate($input: UpdateWorkoutTemplateInput!) {
    updateWorkoutTemplate(input: $input) {
      workoutTemplate {
        id
        name
        focus
        intensity
        level
        notes
        blocks {
          type
          time
          rounds
          name
          notes
          exerciseGroups {
            exercises {
              exercise {
                id
                name
              }
              sets
              reps {
                value
                unit
              }
              load {
                value
                unit
              }
              tempo
              rest
            }
          }
        }
      }
    }
  }
`;

export const DeleteWorkoutTemplateMutation = /* GraphQL */ `
  mutation DeleteWorkoutTemplate($input: DeleteWorkoutTemplateInput!) {
    deleteWorkoutTemplate(input: $input) {
      ok
    }
  }
`;

export const CreateExerciseMutation = /* GraphQL */ `
  mutation CreateExercise($input: CreateExerciseInput!) {
    createExercise(input: $input) {
      exercise {
        id
        name
        description
        videoUrl
        muscleGroup
        equipment
      }
    }
  }
`;

export const UpdateExerciseMutation = /* GraphQL */ `
  mutation UpdateExercise($input: UpdateExerciseInput!) {
    updateExercise(input: $input) {
      exercise {
        id
        name
        description
        videoUrl
        muscleGroup
        equipment
      }
    }
  }
`;

export const DeleteExerciseMutation = /* GraphQL */ `
  mutation DeleteExercise($input: DeleteExerciseInput!) {
    deleteExercise(input: $input) {
      ok
    }
  }
`;

export const CreateUserMutation = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        avatarUrl
        firstName
        lastName
        initials
        fullName
        email
        role {
          id
          name
        }
        status
        superuser
      }
    }
  }
`;

export const ResendUserInvitationMutation = /* GraphQL */ `
  mutation ResendUserInvitation($input: ResendUserInvitationInput!) {
    resendUserInvitation(input: $input) {
      ok
    }
  }
`;

export const CancelUserInvitationMutation = /* GraphQL */ `
  mutation CancelUserInvitation($input: CancelUserInvitationInput!) {
    cancelUserInvitation(input: $input) {
      ok
    }
  }
`;

export const UpdateUserMutation = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        avatarUrl
        firstName
        lastName
        initials
        fullName
        email
        role {
          id
          name
        }
        status
        superuser
      }
    }
  }
`;

export const CreateInstructorMutation = /* GraphQL */ `
  mutation CreateInstructor($input: CreateInstructorInput!) {
    createInstructor(input: $input) {
      instructor {
        id
        avatarUrl
        firstName
        lastName
        initials
        fullName
        nickname
        bio
      }
    }
  }
`;

export const UpdateInstructorMutation = /* GraphQL */ `
  mutation UpdateInstructor($input: UpdateInstructorInput!) {
    updateInstructor(input: $input) {
      instructor {
        id
        avatarUrl
        firstName
        lastName
        initials
        fullName
        nickname
        bio
      }
    }
  }
`;

export const ChangePasswordMutation = /* GraphQL */ `
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      ok
    }
  }
`;
