import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const Pin = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill={color} fillRule="evenodd">
      <path
        d="M22 16.586L25.586 13 27 14.414 23.414 18l3.485 3.485-1.414 1.414L22 19.414 18.515 22.9 17.1 21.485 20.586 18 17 14.414 18.414 13 22 16.586zm75 49.151l2.869-2.868L101 64l-2.869 2.869 2.789 2.788-1.132 1.131L97 68l-2.788 2.788-1.132-1.131 2.789-2.788L93 64l1.131-1.131L97 65.737z"
        opacity=".404"
      />
      <path
        stroke={color}
        d="M60.4 46c-5.854 0-10.6-4.746-10.6-10.6 0-5.854 4.746-10.6 10.6-10.6 5.854 0 10.6 4.746 10.6 10.6C71 41.254 66.254 46 60.4 46zm0-2a8.6 8.6 0 1 0 0-17.2 8.6 8.6 0 0 0 0 17.2zm-19.568 1.397l-.009-.016C38.977 42.015 38 38.343 38 34.5 38 22.048 48.048 12 60.5 12S83 22.048 83 34.5c0 3.859-.938 7.53-2.708 10.652-3.915 7.736-9.897 14.373-19.26 22.022l-.59.483-.622-.442c-6.403-4.558-14.08-13.253-18.988-21.818zm19.531 19.74c8.822-7.282 14.46-13.606 18.167-20.93C80.149 41.35 81 38.018 81 34.5 81 23.152 71.848 14 60.5 14S40 23.152 40 34.5c0 3.497.888 6.837 2.572 9.911 4.61 8.043 11.757 16.23 17.791 20.725z"
      />
    </g>
  </svg>
);

export default Pin;
