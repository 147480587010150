import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useClient } from 'urql';

import { UserProfileQuery } from '../graphql/queries';
import useSession from '../hooks/useSession';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const [session] = useSession();
  useEffect(() => {
    if (!session) {
      setUser(null);
    }
  }, [session]);

  const client = useClient();
  const fetchUser = useCallback(async () => {
    const { data, error } = await client
      .query(UserProfileQuery, null, { requestPolicy: 'network-only' })
      .toPromise();

    if (error) {
      throw error;
    }

    setUser(data.me);
  }, [client]);

  const value = useMemo(() => [user, fetchUser], [user, fetchUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
