/** @jsxImportSource @emotion/react */

import Button from '../Button';
import EmptyState from '../EmptyState';
import Spinner from '../Spinner';
import useTranslate from '../../../hooks/useTranslate';
import { unit } from '../../../theme/sizes';

const Error = ({ retry }) => {
  const t = useTranslate();

  return (
    <EmptyState
      actions={[
        retry && (
          <Button onClick={() => retry()}>
            {t('web.components.UI.Loading.retry')}
          </Button>
        ),
      ]}
      description={t('web.components.UI.Loading.instructions')}
      graphic="broken-dumbbell"
      title={t('web.components.UI.Loading.error')}
    />
  );
};

const Loading = ({ className, error, fullHeight, retry }) => (
  <div
    className={className}
    css={{
      alignItems: 'center',
      display: 'flex',
      height: fullHeight ? '100%' : 'auto',
      justifyContent: 'center',
      width: '100%',
    }}
  >
    {error ? <Error retry={retry} /> : <Spinner size={unit * 5} />}
  </div>
);

export default Loading;
