/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import Graphic from '../Graphic';
import { drukWide } from '../../../theme/fonts';
import { lightGray } from '../../../theme/colors';
import { unit } from '../../../theme/sizes';

const EmptyState = ({
  actions,
  className,
  description,
  fullHeight,
  graphic,
  title,
}) => (
  <div
    className={className}
    css={{
      alignItems: 'center',
      display: 'flex',
      height: fullHeight ? '100%' : 'auto',
      justifyContent: 'center',
      width: '100%',
    }}
  >
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: `${unit * 39}px`,
      }}
    >
      <Graphic css={{ marginBottom: `${unit * 3}px` }} name={graphic} />
      <p
        css={{
          fontFamily: drukWide,
          fontSize: '15px',
          fontWeight: 500,
          fontStyle: 'italic',
          margin: `0 0 ${unit}px`,
          textAlign: 'center',
        }}
      >
        {title}
      </p>
      <p
        css={{
          color: lightGray,
          fontSize: '14px',
          margin: `0 0 ${unit * 3}px`,
          textAlign: 'center',
        }}
      >
        {description}
      </p>
      {actions.length > 0 && (
        <ul
          css={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            listStyle: 'none',
            margin: 0,
            padding: 0,
          }}
        >
          {actions.map((action, index) => (
            <li
              css={{
                display: 'flex',
                '&:not(:last-of-type)': {
                  marginBottom: `${unit}px`,
                },
              }}
              key={index} // eslint-disable-line
            >
              {action}
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
);

EmptyState.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.element.isRequired),
  description: PropTypes.string.isRequired,
  fullHeight: PropTypes.bool,
  graphic: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

EmptyState.defaultProps = {
  actions: [],
  fullHeight: false,
};

export default EmptyState;
