import Div100vh from 'react-div-100vh';

import App from './App';
import AppProviders from './context';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalStyles from './components/GlobalStyles';

const Root = () => (
  <Div100vh>
    <GlobalStyles />
    <ErrorBoundary>
      <AppProviders>
        <App />
      </AppProviders>
    </ErrorBoundary>
  </Div100vh>
);

export default Root;
