import { cacheExchange } from '@urql/exchange-graphcache';
import { parseISO } from 'date-fns';

import {
  AddClassSessionsFormQuery,
  ClassWorkoutListQuery,
  ExerciseListQuery,
  MemberCreditsPurchaseHistoryQuery,
  MemberWorkoutListQuery,
  UserListQuery,
  WorkoutTemplateListQuery,
} from '../../graphql/queries';

const invalidateAll = (cache, field, entity = { __typename: 'Query' }) => {
  cache
    .inspectFields(entity)
    .filter(({ fieldName }) => fieldName === field)
    .forEach(({ fieldName, arguments: args }) => {
      cache.invalidate(entity, fieldName, args);
    });
};

export default cacheExchange({
  keys: {
    ActiveMembership: () => null,
    ExerciseLoad: () => null,
    ExerciseReps: () => null,
    WorkoutBlock: () => null,
    WorkoutBlockExercise: () => null,
    WorkoutBlockExerciseGroup: () => null,
  },
  resolvers: {
    Booking: {
      createdAt: ({ createdAt }) => parseISO(createdAt),
      canceledAt: ({ canceledAt }) => canceledAt && parseISO(canceledAt),
    },
    ClassSession: {
      startAt: ({ startAt }) => parseISO(startAt),
      endAt: ({ endAt }) => parseISO(endAt),
      canceledAt: ({ canceledAt }) => canceledAt && parseISO(canceledAt),
    },
    Member: {
      createdAt: ({ createdAt }) => parseISO(createdAt),
      dateOfBirth: ({ dateOfBirth }) => dateOfBirth && parseISO(dateOfBirth),
    },
    Membership: {
      createdAt: ({ createdAt }) => parseISO(createdAt),
      expireAt: ({ expireAt }) => parseISO(expireAt),
    },
    PlanWorkout: {
      date: ({ date }) => parseISO(date),
    },
  },
  updates: {
    Mutation: {
      /*
       * Members.
       */
      createMember: (_result, _args, cache) => {
        invalidateAll(cache, 'members'); // Search results.
        invalidateAll(cache, 'membersConnection');
      },
      /*
       * Member Credits.
       */
      addMemberCredits: (result, args, cache) => {
        const {
          addMemberCredits: { membership },
        } = result;
        const {
          input: { memberId },
        } = args;
        cache.updateQuery(
          {
            query: MemberCreditsPurchaseHistoryQuery,
            variables: {
              id: memberId,
            },
          },
          (data) =>
            data && {
              ...data,
              member: {
                ...data.member,
                memberships: [membership, ...data.member.memberships],
              },
            },
        );
        cache.invalidate(
          { __typename: 'Member', id: memberId },
          'activeMembership',
        );
      },
      /*
       * Member Plan.
       */
      createMemberWorkout: (result, args, cache) => {
        const {
          createMemberWorkout: { workout },
        } = result;
        const {
          input: { plannableId: memberId },
        } = args;
        cache.updateQuery(
          {
            query: MemberWorkoutListQuery,
            variables: {
              id: memberId,
            },
          },
          (data) =>
            data && {
              ...data,
              member: {
                ...data.member,
                workouts: [workout, ...data.member.workouts],
              },
            },
        );
      },
      deleteMemberWorkout: (_result, args, cache) => {
        const {
          input: { id: workoutId },
        } = args;
        cache.invalidate({ __typename: 'PlanWorkout', id: workoutId });
      },
      /*
       * Classes.
       */
      createClass: (_result, _args, cache) => {
        invalidateAll(cache, 'classesConnection');
      },
      deleteClass: (_result, args, cache) => {
        const {
          input: { id: classId },
        } = args;
        cache.invalidate({ __typename: 'Class', id: classId });
      },
      createClassSessions: (_result, args, cache) => {
        const {
          input: { classId },
        } = args;
        invalidateAll(cache, 'timetable', { __typename: 'Class', id: classId });
      },
      cancelClassSession: (_result, args, cache) => {
        const {
          input: { id: classSessionId },
        } = args;
        cache.invalidate({ __typename: 'ClassSession', id: classSessionId });
      },
      /*
       * Class Plan.
       */
      createClassWorkout: (result, args, cache) => {
        const {
          createClassWorkout: { workout },
        } = result;
        const {
          input: { plannableId: classId },
        } = args;
        cache.updateQuery(
          {
            query: ClassWorkoutListQuery,
            variables: {
              id: classId,
            },
          },
          (data) =>
            data && {
              ...data,
              class: {
                ...data.class,
                workouts: [workout, ...data.class.workouts],
              },
            },
        );
      },
      deleteClassWorkout: (_result, args, cache) => {
        const {
          input: { id: workoutId },
        } = args;
        cache.invalidate({ __typename: 'PlanWorkout', id: workoutId });
      },
      /*
       * Workout Templates.
       */
      createWorkoutTemplate: (result, _args, cache) => {
        const {
          createWorkoutTemplate: { workoutTemplate },
        } = result;
        cache.updateQuery(
          { query: WorkoutTemplateListQuery },
          (data) =>
            data && {
              ...data,
              workoutTemplates: [
                ...data.workoutTemplates,
                workoutTemplate,
              ].sort((a, b) => a.name.localeCompare(b.name)),
            },
        );
      },
      deleteWorkoutTemplate: (_result, args, cache) => {
        const {
          input: { id: workoutTemplateId },
        } = args;
        cache.invalidate({
          __typename: 'WorkoutTemplate',
          id: workoutTemplateId,
        });
      },
      /*
       * Exercises.
       */
      createExercise: (result, _args, cache) => {
        const {
          createExercise: { exercise },
        } = result;
        cache.updateQuery(
          { query: ExerciseListQuery },
          (data) =>
            data && {
              ...data,
              exercises: [...data.exercises, exercise].sort((a, b) =>
                a.name.localeCompare(b.name),
              ),
            },
        );
        invalidateAll(cache, 'exercisesConnection');
      },
      deleteExercise: (_result, args, cache) => {
        const {
          input: { id: exerciseId },
        } = args;
        cache.invalidate({ __typename: 'Exercise', id: exerciseId });
      },
      /*
       * Users.
       */
      createUser: (result, _args, cache) => {
        const {
          createUser: { user },
        } = result;
        cache.updateQuery(
          { query: UserListQuery },
          (data) =>
            data && {
              ...data,
              users: [...data.users, user].sort((a, b) =>
                a.fullName.localeCompare(b.fullName),
              ),
            },
        );
        invalidateAll(cache, 'usersConnection');
      },
      cancelUserInvitation: (_result, args, cache) => {
        const {
          input: { id: userId },
        } = args;
        cache.invalidate({ __typename: 'User', id: userId });
        invalidateAll(cache, 'usersConnection');
      },
      /*
       * Instructors.
       */
      createInstructor: (result, _args, cache) => {
        const {
          createInstructor: { instructor },
        } = result;
        cache.updateQuery(
          { query: AddClassSessionsFormQuery },
          (data) =>
            data && {
              ...data,
              instructors: [...data.instructors, instructor].sort((a, b) =>
                a.fullName.localeCompare(b.fullName),
              ),
            },
        );
        invalidateAll(cache, 'instructorsConnection');
      },
    },
  },
});
