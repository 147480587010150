/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { lighten } from 'polished';

import Spinner from '../Spinner';
import elementTypePropTypeValidator from '../../../utils/elementTypePropTypeValidator';
import {
  accent,
  darkestGray,
  gray,
  lightGray,
  white,
  whiteSmoke,
} from '../../../theme/colors';
import { barlowSemiCondensed, drukWide } from '../../../theme/fonts';
import { hover } from '../../../theme/media';
import { unit } from '../../../theme/sizes';

const horizontalPadding = unit * 4;
const spinnerMargin = unit;
const spinnerSize = unit * 3 - 4;

const baseStyle = {
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
  display: 'inline-flex',
  fontFamily: drukWide,
  fontStyle: 'italic',
  fontWeight: 500,
  justifyContent: 'center',
  padding: `0 ${horizontalPadding}px`,
  textDecoration: 'none',
  textTransform: 'uppercase',
  transition:
    'background-color 200ms ease, color 200ms ease, opacity 200ms ease',
  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    cursor: 'default',
  },
};

const sizeStyles = {
  'x-small': {
    fontSize: '10px',
    height: `${unit * 3}px`,
    minWidth: `${unit * 20}px`,
  },
  small: {
    fontSize: '12px',
    height: `${unit * 4}px`,
    minWidth: `${unit * 20}px`,
  },
  medium: {
    fontSize: '14px',
    height: `${unit * 5}px`,
    minWidth: `${unit * 20}px`,
  },
  large: {
    fontSize: '16px',
    height: `${unit * 6}px`,
    minWidth: `${unit * 20}px`,
  },
};

const variantStyles = {
  primary: {
    backgroundColor: accent,
    color: darkestGray,
    [`@media ${hover}`]: {
      '&:hover:not(:disabled)': {
        backgroundColor: lighten(0.027, accent),
      },
    },
    '&:active:not(:disabled)': {
      backgroundColor: lighten(0.027, accent),
    },
  },
  secondary: {
    backgroundColor: white,
    color: darkestGray,
    [`@media ${hover}`]: {
      '&:hover:not(:disabled)': {
        backgroundColor: whiteSmoke,
      },
    },
    '&:active:not(:disabled)': {
      backgroundColor: whiteSmoke,
    },
  },
  text: {
    background: 'none',
    color: white,
    height: 'auto',
    minWidth: 'auto',
    padding: 0,
    whiteSpace: 'pre',
    [`@media ${hover}`]: {
      '&:hover:not(:disabled)': {
        color: accent,
      },
    },
    '&:active:not(:disabled)': {
      color: accent,
    },
  },
  'text-secondary': {
    background: 'none',
    color: gray,
    height: 'auto',
    minWidth: 'auto',
    padding: 0,
    whiteSpace: 'pre',
    [`@media ${hover}`]: {
      '&:hover:not(:disabled)': {
        color: lightGray,
      },
    },
    '&:active:not(:disabled)': {
      color: white,
    },
  },
  link: {
    background: 'none',
    color: lightGray,
    fontFamily: barlowSemiCondensed,
    fontStyle: 'normal',
    height: 'auto',
    minWidth: 'auto',
    padding: 0,
    textDecoration: 'underline',
    textTransform: 'none',
  },
};

const disabledStyle = {
  opacity: 0.3,
};

const fullWidthStyle = {
  width: '100%',
};

const loadingStyle = {
  padding: `0 ${horizontalPadding - (spinnerSize + spinnerMargin) / 2}px`,
};

const noUppercaseStyle = {
  textTransform: 'none',
};

const Button = ({
  children,
  className,
  component: Component,
  disabled,
  fullWidth,
  loading,
  noUppercase,
  size,
  type,
  variant,
  ...other
}) => {
  const buttonProps = {};
  if (Component === 'button') {
    buttonProps.type = type;
    buttonProps.disabled = disabled || loading;
  }

  return (
    <Component
      className={className}
      css={[
        baseStyle,
        sizeStyles[size],
        variantStyles[variant],
        disabled && disabledStyle,
        fullWidth && fullWidthStyle,
        loading && loadingStyle,
        noUppercase && noUppercaseStyle,
      ]}
      {...buttonProps}
      {...other}
    >
      {loading && (
        <Spinner
          css={{ marginRight: `${spinnerMargin}px` }}
          size={spinnerSize}
          variant="secondary"
        />
      )}
      {children}
    </Component>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  component: elementTypePropTypeValidator,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  noUppercase: PropTypes.bool,
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'text',
    'text-secondary',
    'link',
  ]),
};

Button.defaultProps = {
  component: 'button',
  disabled: false,
  fullWidth: false,
  loading: false,
  noUppercase: false,
  size: 'medium',
  type: 'button',
  variant: 'primary',
};

export default Button;
