/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { keyframes } from '@emotion/react';

import { accent, black } from '../../../theme/colors';
import { unit } from '../../../theme/sizes';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const computeOuterBaseStyle = (size) => ({
  animation: `${spin} 1s linear infinite`,
  borderRadius: '50%',
  display: 'inline-flex',
  position: 'relative',
  height: `${size}px`,
  width: `${size}px`,
});

const computeOuterVariantStyles = (variant, background) => {
  const outerVariantStyles = {
    primary: {
      backgroundImage: `conic-gradient(${background}, ${accent})`,
    },
    secondary: {
      backgroundImage: `conic-gradient(${accent}, ${background})`,
    },
  };

  return outerVariantStyles[variant];
};

const innerBaseStyle = {
  borderRadius: '50%',
  height: '64%',
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '64%',
};

const computeInnerVariantStyles = (variant, background) => {
  const innerVariantStyles = {
    primary: {
      backgroundColor: background,
    },
    secondary: {
      backgroundColor: accent,
    },
  };

  return innerVariantStyles[variant];
};

const Spinner = ({ background, className, size, variant }) => (
  <div
    className={className}
    css={[
      computeOuterBaseStyle(size),
      computeOuterVariantStyles(variant, background),
    ]}
  >
    <div
      css={[innerBaseStyle, computeInnerVariantStyles(variant, background)]}
    />
  </div>
);

Spinner.propTypes = {
  background: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

Spinner.defaultProps = {
  background: black,
  size: unit * 3,
  variant: 'primary',
};

export default Spinner;
