import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

const locale = process.env.REACT_APP_LOCALE;
const locales = {
  en: enLocale,
  es: esLocale,
};

/**
 * @deprecated use the `useDateFnsLocale` hook instead.
 */
const getDateFnsLocale = () => locales[locale];

export default getDateFnsLocale;
