import { useMemo } from 'react';
import { Provider } from 'urql';

import makeClient from '../client';
import useFetcher from '../hooks/useFetcher';

// Wrapper around urql's Provider.
export const GraphQLClientProvider = ({ children }) => {
  const fetcher = useFetcher();

  const client = useMemo(() => makeClient(fetcher), [fetcher]);

  return <Provider value={client}>{children}</Provider>;
};
