import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

import getDateFnsLocale from '../utils/getDateFnsLocale';
import { barlowSemiCondensed } from '../theme/fonts';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: barlowSemiCondensed,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiPaper: {
      square: true,
    },
  },
});

export const MaterialUIProvider = ({ children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <MuiPickersUtilsProvider locale={getDateFnsLocale()} utils={DateFnsUtils}>
      {children}
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);
