import { unit } from '../../../theme/sizes';
import { white } from '../../../theme/colors';

const Dumbbell = ({ color = white, size = unit * 15, ...other }) => (
  <svg width={size} viewBox="0 0 120 80" {...other}>
    <g fill={color} fillRule="evenodd">
      <path
        fillRule="nonzero"
        stroke={color}
        strokeWidth=".72"
        d="M69.787 34.23V18h16v12.015H96v18.97H85.787V61h-16V44.77H50.32V61h-16V48.985H24v-18.97h10.32V18h16v16.23h19.467zm0 8.748v-6.956H50.32v6.956h19.467zm14.186-12.963V20.15H71.6v38.7h12.373v-9.865h-.106v-18.97h.106zm1.814 1.791v15.388h8.4V31.806h-8.4zm-51.467 0h-8.507v15.388h8.507V31.806zm1.813 17.18v9.864h12.374v-38.7H36.133v28.835z"
      />
      <path
        d="M6 61.586L9.586 58 11 59.414 7.414 63l3.485 3.485L9.485 67.9 6 64.414 2.515 67.9 1.1 66.485 4.586 63 1 59.414 2.414 58 6 61.586zM114 10.737l2.869-2.868L118 9l-2.869 2.869 2.789 2.788-1.132 1.131L114 13l-2.788 2.788-1.132-1.131 2.789-2.788L110 9l1.131-1.131L114 10.737z"
        opacity=".404"
      />
    </g>
  </svg>
);

export default Dumbbell;
